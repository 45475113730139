@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "header";
@import "footer";
@import "table";
@import "editor";
@import "loginForm";
@import "createForm";

@font-face {
  font-family: Caecilia;
  src: url("../assets/fonts/caecilia.eot");
  src: url("../assets/fonts/caecilia.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/caecilia.woff") format("woff"),
    url("../assets/fonts/caecilia.ttf") format("truetype"),
    url("../assets/fonts/caecilia.svg") format("svg");
}

@font-face {
  font-family: CorporateS;
  src: url("../assets/fonts/corporateS.eot");
  src: url("../assets/fonts/corporateS.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/corporateS.woff") format("woff"),
    url("../assets/fonts/corporateS.ttf") format("truetype"),
    url("../assets/fonts/corporateS.svg") format("svg");
}

:root {
  --header-height: 10vh;
  --footer-height: 3vh;
  --body-height: 90vh;
  --main-background-color: #e5e5e5;
  --main-text-color: #000000;
  --main-text-color-reversed: #ffffff;
  --header-background-color: #dbd8c5;
  --header2-background-color: #edebdd;
  --table-background: #fefefe;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "CorporateS", sans-serif;
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
}

.container {
  padding: 1%;
  position: relative;
  min-height: 100%;
  padding-bottom: 3rem;
}

.container-admin {
  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-gap: 1%;
  align-items: flex-start;
}

.container-admin-table {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
}

.container-admin-editor {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
}
