.header {
  width: 100%;
  position: relative;
  top: 0;
}

.header-container {
  height: var(--header-height);
  width: 100%;
  background-color: var(--header-background-color);
  display: block;
}

.header-container1 {
  height: var(--header-height);
  width: 100%;
  background-color: var(--header2-background-color);
  display: block;
}

.logo-container {
  display: flex;
  align-items: center;
  max-height: 100%;
  height: 100%;
  margin-left: 1%;
  float: left;
}

.logo {
  height: 50%;
  padding-right: 5%;
}

.logo-title {
  color: var(--main-text-color);
  font-size: 2.75vh;
  padding-left: 1%;
  white-space: nowrap;
  font-family: "Caecilia", sans-serif;
}

.header-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 6px;
}

.normal-button {
  border: 1px solid var(--main-text-color-reversed);
  border-radius: 4px;
  padding: 4px 6px;
  margin-right: 1%;
  display: block;
  background-color: var(--main-text-color-reversed);
  color: var(--main-text-color);
  font-weight: bold;
  font-size: 2vh;
}

.button-link,
.button-link:active {
  color: var(--main-text-color);
}

.button-span {
  padding-left: 4%;
  padding-right: 4%;
}

.dropdown-language {
  -webkit-appearance: menulist-button;
  border: 1px solid var(--main-text-color-reversed);
  border-radius: 4px;
  padding: 3px;
  margin-right: 1.5em;
  display: block;
  background-color: var(--main-text-color-reversed);
  color: var(--main-text-color);
  font-weight: bold;
  font-size: 2vh;
}
