.editor-form {
  width: 100%;
  padding: 2.5%;
  padding-top: 5%;
  background: var(--header-background-color);
  box-sizing: border-box;
  box-shadow: 0 15px 35px var(--header-background-color);
  border-radius: 8px;
}

.editor-form .form-columns {
  display: flex;
  justify-content: space-between;
}

.editor-form .form-column {
  width: 45%;
}

.editor-form .user-box,
.editor-form .user-box-checkbox,
.editor-form .user-box-date,
.editor-form .user-box-textarea {
  position: relative;
}

.editor-form .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--main-text-color);
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid var(--main-text-color);
  outline: none;
  background: transparent;
}

.editor-form .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: var(--main-text-color);
  pointer-events: none;
  transition: 0.5s;
}

.editor-form .user-box input:focus ~ label,
.editor-form .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: var(--main-text-color);
  font-size: 12px;
}

.editor-form .user-box-checkbox input {
  margin-bottom: 30px;
}

.editor-form .user-box-checkbox label {
  position: absolute;
  top: 0;
  padding-left: 10px;
  font-size: 16px;
  color: var(--main-text-color);
}

.editor-form .user-box-date input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--main-text-color);
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid var(--main-text-color);
  outline: none;
  background: transparent;
}

.editor-form .user-box-date label {
  position: absolute;
  top: -20px;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: var(--main-text-color);
  pointer-events: none;
  transition: 0.5s;
}

.editor-form .user-box-textarea textarea {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  margin-top: 10px;
  color: var(--main-text-color);
  border: 1px solid var(--main-text-color);
  outline: none;
  background: transparent;
}

.editor-form .user-box-textarea label {
  position: absolute;
  top: -20px;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: var(--main-text-color);
  pointer-events: none;
  transition: 0.5s;
}

.editor-form .edit-button {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  border: 1px solid var(--main-text-color-reversed);
  border-radius: 4px;
  background-color: var(--main-text-color-reversed);
  color: var(--main-text-color);
  font-weight: bold;
  font-size: 2vh;
}

.editor-form .delete-button {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  margin-top: 10px;
  border: 1px solid #b88ca4;
  border-radius: 4px;
  background-color: #b88ca4;
  color: #fff;
  font-weight: bold;
  font-size: 2vh;
}
