.footer-container {
  height: var(--footer-height);
  width: 100%;
  background-color: var(--header-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
}

.footer-text {
  font-weight: bold;
}
