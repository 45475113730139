.table-container {
  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      background-color: var(--table-background);

      &:nth-child(even) {
        background-color: #edebdd;
      }

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border: 1px solid black;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 1px;

      :last-child {
        border-right: 0;
      }
    }
  }
}

.tableWrap {
  display: block;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom: 1px solid black;
}

.true-badge {
  background-color: #7d9c81;
  color: var(--main-text-color);
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.false-badge {
  background-color: #b88ca4;
  color: var(--main-text-color);
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.selected-row {
  background-color: var(--header-background-color);
}
