.login-box {
  position: absolute;
  top: 400%;
  left: 50%;
  width: 25%;
  padding: 3.5%;
  transform: translate(-50%, -50%);
  background: var(--header-background-color);
  box-sizing: border-box;
  box-shadow: 0 15px 35px var(--header-background-color);
  border-radius: 8px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: var(--main-text-color);
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: var(--main-text-color);
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid var(--main-text-color);
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: var(--main-text-color);
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: var(--main-text-color);
  font-size: 12px;
}

.login-box .submit-button {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid var(--main-text-color-reversed);
  border-radius: 4px;
  background-color: var(--main-text-color-reversed);
  color: var(--main-text-color);
  font-weight: bold;
  font-size: 2vh;
}

.login-loading-spinner {
  width: 100%;
  height: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
